import React from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { useNFOutrasOperacao } from '~/pages/NFOutrasOperacoes/NFOutrasOperacoesContext';
import { ItensFinaceiroProps } from '~/pages/NFOutrasOperacoes/protocols/ItensFinaceiroProps';
import CustomDataGrid from '../../../myComponents/CustomDataGrid';
import { ContainerAcoes } from './styles';
import { Financeiro } from '~/pages/EmissaoNFE/screens/Financeiro';

const TableFinanceiro: React.FC = () => {
  const { parcelasFinanceiro, updateItemFinaceiro, deleteItemFinaceiro } =
    useNFOutrasOperacao();

  const columns = [
    {
      field: 'num_condicao',
      headerName: 'Prazo',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_definicao',
      headerName: 'Condição',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dta_vencimento',
      headerName: 'Vencimento',
      width: 200,
      format: 'dta',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'val_parcela',
      headerName: 'Valor da Parcela',
      width: 150,
      format: 'money',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'flg_quitado',
      headerName: 'Quitado?',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row: ItensFinaceiroProps) => {
        return (
          <ContainerAcoes>
            <input
              type="checkbox"
              id="flg_quitado"
              disabled
              checked={row.flg_quitado}
            />
          </ContainerAcoes>
        );
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'right',
      width: 100,
      renderCell: (row: ItensFinaceiroProps) => {
        return (
          <ContainerAcoes>
            <button
              type="button"
              disabled={row.isDisabled}
              onClick={() => updateItemFinaceiro(row, row.num_registro)}
            >
              <MdEdit color="#FE9901" />
            </button>
            <button
              type="button"
              disabled={row.isDisabled}
              onClick={() => deleteItemFinaceiro(row.uuid)}
            >
              <BsTrashFill color="#CF2C2D" />
            </button>
          </ContainerAcoes>
        );
      },
    },
  ];

  return (
    <CustomDataGrid
      key="Financeiro"
      rows={parcelasFinanceiro}
      columns={columns}
    />
  );
};

export default TableFinanceiro;
